@import "../../../variables.scss";

.navbar {
  background-color: transparent;
  width: 100vw;
  padding: 16px 32px;
  position: fixed;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background-color 0.3s ease;

  &.scroll {
    background-color: $background-color;
  }

  .flex {
    column-gap: 32px;
    text-align: right;
    justify-content: end;

    a {
      color: white;
      text-decoration: none;
      font-weight: 600;
      font-size: 16px;
      line-height: 110%;
    }

    &.menu-items {
      display: none;
    }
  }

  @media only screen and (max-width: 767px) {
    & {
      background-color: $background-color !important;
    }

    .right:hover .menu-items {
      background-color: $background-color !important;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
      position: absolute;
      right: 0;
      top: 72px;
      width: 100%;
      padding: 24px;
    }
  }

  @media only screen and (min-width: 768px) {
    .flex.menu-items {
      display: flex;
      align-items: center;
    }
  }
}
