@import "../../../../variables.scss";

.section {
  &-impact {
    padding: 20px;
    background: url("../../../../../public/assets/background-impact.png") no-repeat right;
    background-size: cover;
    color: white;

    video {
      aspect-ratio: 16/9;
      min-width: 50vw;
      max-width: 60vw;
      max-height: 50vh;
    }

    .introduction, .subtitle {
      text-align: justify;
    }

    p.subtitle {
      font-size: smaller !important;
    }

    .features {
      margin-top: 32px;
      display: flex;
      row-gap: 24px;
      flex-direction: column;
      column-gap: 64px;
      margin-left: 10%;
      width: 80%;
      padding-bottom: 16px;


      .feature {
        text-align: center;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
          font-weight: 600;
          font-size: 1.5em;
          line-height: 100%;
          height: 42px;
          text-transform: uppercase;
          width: 70%;
          margin: 16px auto 4px;;
        }

        img {
          width: 150%;
        }
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .section {
    &-impact {
      .features {
        .feature {
          img {
            width: 50%;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .section {
    &-impact {
      padding: 32px 64px 96px;

      .features {
        flex-direction: row;

        .feature {
          h3 {
            font-size: 20px;
            line-height: 110%;
          }

          img {
            width: 125%;
          }
        }
      }
    }
  }
}
