@import "../../../variables.scss";

.video-player {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 1;

  video,
  .close {
    width: 80vw;
    max-width: 1200px;
  }

  .close {
    text-align: right;
  }
}
