@import "../../../../variables.scss";

.section {
  &-footer {
    padding: 64px 20px;
    border-top: 1px $dark-gray-background-color groove;

    a {
      text-decoration: none;
      color: black;
    }

    .flex strong {
      margin-right: 4px;
    }

    p,
    b {
      margin: 0;
      font-size: 12px;
      line-height: 1.29;
    }

    .contacts {
      column-gap: 32px;
    }

    .contacts, .newsletter {
      flex: 1;
    }

    .footer {
      justify-content: space-between;
      column-gap: 48px;
      flex-wrap: wrap;
    }

    .newsletter p {
      font-size: 16px;
      max-width: 200px;
      margin: 0 0 16px;
    }

    img.logo {
      margin: 0 0 16px;
      width: 150px;
    }

    .social {
      display: flex;
      column-gap: 16px;
      justify-content: center;
      margin-bottom: 32px;

      a {
        transition: transform 0.3s ease; /* Add a transition for a smooth effect */

        &:hover {
          transform: translateY(-8px);
        }

        img {
          width: 48px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .section {
    &-footer {
      padding: 64px 128px;

      p,
      b {
        font-size: 16px;
      }

      .flex {
        img {
          margin: 0 64px;
        }
      }

      img.logo {
        margin: 0 0 32px;
        width: 250px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .section {
    &-footer {
      .contacts, .newsletter {
        flex-basis: 100%;
      }

      .newsletter {
        margin-top: 32px;
      }
    }
  }
}
