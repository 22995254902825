.section {
  &-hero {
    height: 220px;
    position: relative;
    margin-top: 84px;
    width: 100vw;
    overflow: hidden;

    video {
      position: absolute;
      width: 100%;
      z-index: 0;
    }

    .title-hero {
      position: absolute;
      top: 50%;
      left: 16px;
      max-width: calc(100vw - 64px);
      transform: translateY(-50%);
      color: white;
      text-transform: uppercase;

      h2 {
        margin: 0 0 8px;
        font-weight: 900;
        font-size: 2.2em;
        line-height: 95%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .section {
    &-hero {
      height: 320px;

      .title-hero {
        top: 75%;

        h2 {
          font-size: 3em;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .section {
    &-hero {
      height: 512px;

      .title-hero {
        left: 64px;
        top: 70%;

        h2 {
          font-size: 4em;
        }
      }
    }
  }
}

@media only screen and (min-width: 1440px) {
  .section {
    &-hero {
      height: 650px;

      .title-hero {
        left: 64px;
        top: 35%;

        h2 {
          font-size: 4em;
        }
      }
    }
  }
}


@media only screen and (min-width: 768px) {
  .section {
    &-hero {
      margin-top: 0;
    }
  }
}