@import "../../../variables.scss";

button {
  &.button {
    border: none;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    color: white;
    padding: 8px;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
    display: flex;
    width: fit-content;

    &:hover {
      opacity: 0.5;
      cursor: pointer;
    }

    .arrow {
      margin-left: 10.5px;
    }

    &-red {
      background-color: $red-color;
    }

    &-blue {
      background-color: $blue-button-color;
    }

    &-primary {
      background-color: $primary-color;
    }

    &:disabled {
      opacity: 0.5;
      pointer-events: none;

      &:hover {
        cursor: not-allowed;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  button {
    &.button {
      font-size: 18px;
    }
  }
}
