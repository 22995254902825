@import "../../variables.scss";

.login {
  background-image: url("../../../public/assets/background-login.svg");
  background-position: bottom 0 right 20%;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  position: relative;
  padding: 32px;

  .form {
    position: absolute;
    width: calc(100vw - 64px);
    top: 30vh;
    left: 32px;
    color: white;

    h2 {
      font-weight: 400;
      font-size: 26px;
      line-height: 110%;
      letter-spacing: 0.03em;
    }

    p,
    a {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
    }

    p {
      margin: 32px auto 8px;
      color: $gray-color;
    }

    a {
      color: #578af4;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    small {
      display: block;
      margin: -8px auto 16px;
      color: $red-color;
    }

    input {
      background: linear-gradient(180deg, #d9d9d9 0%, #fefefe 14.06%, #ffffff 100%);
      mix-blend-mode: normal;
      border: 1px solid #cccccc;
      border-radius: 2px;
      width: 100%;
      padding: 6px 8px;
      font-size: 15px;
      margin-bottom: 16px;
    }

    .flex {
      justify-content: space-between;
      width: 100%;

      button.button {
        padding: 10px 16px;

        span {
          text-transform: uppercase;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.02em;
        }
      }

      .vertical {
        display: flex;
        flex-direction: column;
      }

      &.actions-right {
        justify-content: end;
      }
    }
  }

  @media only screen and (min-width: 600px) and (max-width: 1024px) {
    .form {
      width: 70vw;
      left: 15vw;
    }
  }

  @media only screen and (min-width: 1024px) {
    .form {
      width: 30vw;
      left: 10vw;
    }
  }
}
