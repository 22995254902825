.main {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  .section {
    &-welcome,
    &-impact,
    &-ecosystem,
    &-hero,
    &-intro,
    &-immersive,
    &-downloads,
    &-contact,
    &-footer {
      width: 100%;
    }

    &-impact,
    &-ecosystem {
      text-align: center;
    }

    &-welcome,
    &-impact,
    &-ecosystem {
      h2 {
        text-transform: uppercase;
        font-weight: 900;
        font-size: 2em;
        line-height: 100%;
        margin-bottom: 32px;
      }

      p {
        margin-top: 4px;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
      }
    }

    &-welcome,
    &-impact,
    &-immersive,
    &-downloads {
      position: relative;

      .anchor {
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        z-index: -1;
        top: -84px;
        left: 0;
        visibility: hidden;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .section {
      &-intro,
      &-welcome {
        width: 50%;
        height: auto;
      }

      &-welcome,
      &-impact,
      &-ecosystem,
      &-contact {
        h2 {
          font-size: 35px;
          line-height: 100%;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}
