@import "./variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

.App {
  min-height: 100vh;
  max-width: 100vw;
  font-family: "Source Sans Pro";
  color: $black-color;

  .right {
    float: right;
    text-align: right;
  }

  .red {
    color: $red-color;
  }

  .spacing {
    &-32 {
      height: 32px;
    }

    &-16 {
      height: 16px;
    }

    &-8 {
      height: 8px;
    }
  }

  div[class*="spacing"] {
    width: 100%;
  }

  .flex {
    display: flex;

    .col {
      &-6 {
        width: 50%;
      }
    }
  }

  .hover:hover {
    cursor: pointer;
  }
}
