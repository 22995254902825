.section {
  &-welcome {
    padding: 24px 20px;
    background: white;

    h4 {
      font-size: 1.5em;
    }

    h4, h2 {
      margin: 0 !important;
      margin-bottom: 8px !important;
    }

    p {
      text-align: justify;
    }
  }

}

@media only screen and (min-width: 1024px) {
  .section {
    &-welcome {
      padding: 64px;
    }
  }
}
