$primary-color: #1f58cf;
$background-color: #00003c;
$secondary-background-color: #13036c;
$blue-background-color: #0072b1;
$red-color: #e22222;
$red-background-color: #b30000;
$gray-background-color: #404040;
$black-color: #00003c;
$blue-button-color: #2b5abc;
$gray-color: #7f7f7f;
$dark-gray-background-color: #6c6c6c;
$light-gray-background-color: #efefef;
